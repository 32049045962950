.icon {
  color: white;
  width: auto;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.icon-large {
  color: white;
  width: auto;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 2rem;
}

.icon-medium {
  color: white;
  width: auto;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.75rem;
}

.icon-small {
  color: white;
  width: auto;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}


.yScrollBarWrapper-white{
  overflow-y: auto;
  --scrollbarBG: #00000021;
  --thumbBG: #ffffff;
}
.yScrollBarWrapper-white:hover {
  --thumbBG: #ffffffc2;
}
.yScrollBarWrapper-white::-webkit-scrollbar {
  width: .5em;
}
.yScrollBarWrapper-white{
  scrollbar-width: thin !important;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.yScrollBarWrapper-white::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.yScrollBarWrapper-white::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 4px;
  border: 2px solid var(--scrollbarBG) transparent;
}
